'use client';

import { SessionProvider } from 'next-auth/react';

// Components
import { Content } from './Content';
import { Suspense } from 'react';

const Page = () => (
  <Suspense>
    <SessionProvider>
      <Content />
    </SessionProvider>
  </Suspense>
);

export default Page;
