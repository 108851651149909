'use client';

import { useEffect } from 'react';
import { signIn, useSession } from 'next-auth/react';
import { useRouter, useSearchParams } from 'next/navigation';

export const Content = () => {
  const router = useRouter();
  const { status, data } = useSession();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (
      status === 'unauthenticated' ||
      data?.error === 'RefreshAccessTokenError'
    ) {
      signIn('azure-ad-b2c');
    } else if (status === 'authenticated') {
      const callbackUrl = searchParams.get('callbackUrl');
      router.push(callbackUrl ?? '/');
    }
  }, [status, data]);

  return <div></div>;
};
